import React from "react";
import { Typography, Box, Paper } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export interface CourseProps {
  title: string;
  description: string;
  status: string;
}

export default function CourseCard({ course }: any) {
  const classes = useStyles();
  const {
    attributes: {
      collection: {
        attributes: { title, last_chapter },
      },
      course_status,
    },
  } = course;

  return (
    <Paper className={classes.paper}>
      <Box>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.description}>{last_chapter}</Typography>
      </Box>
      <Box className={classes.statusContainer}>
        <Typography className={classes.status}>
          {course_status === "progress" ? "in progress" : course_status}
        </Typography>
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
      boxShadow: "0px 0px 16px rgba(191, 190, 190, 0.350581)",
      borderRadius: "8px",
      borderLeft: "9px solid #FF8B3F",
      // marginBottom: "15px",
      cursor: "pointer",
      padding: "15px 10px",
      [theme.breakpoints.up("md")]: {
        padding: "15px 25px",
        flexDirection: "row",
        alignItems: "center",
      },
    },
    title: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "17px",
      color: "#131313",
      textTransform: "capitalize",
    },
    description: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "22px",
      color: "#202860",
      textTransform: "capitalize",
      marginTop: "3px",
    },
    statusContainer: {
      background: "#FF8B3F",
      borderRadius: "6px",
      padding: "1px 21px",
      marginTop: "5px",
      [theme.breakpoints.up("md")]: {
        marginTop: 0,
      },
    },
    status: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "22px",
      textAlign: "center",
      color: "#FFFFFF",
      textTransform: "capitalize",
    },
  })
);
