import React from "react";
import { Typography, Box, Paper } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SelectDropdown from "../../../components/src/SelectDropdown";
import CircularProgressWithLabel from "../../../components/src/CircularProgressWithLabel";
import { RewardFlag, TimeWatchedFlag, ProgressFlag } from "./assets";

const timeFrameOptions = [{ id: 1, label: "All Time", value: "all-time" }];

interface Props {
  timeFrame: string;
  timeWatched: number;
  totalTimeWatched: number;
  completedRewards: number;
  totalRewards: number;
  courseOverallProgress: number;
  handleStatTimeFrameChange: any;
}

const STAT_OPTIONS = [
  { id: 4, label: "All Time", value: "" },
  { id: 3, label: "This Year", value: "year" },
  { id: 2, label: "This Month", value: "month" },
  { id: 1, label: "This Week", value: "week" },
];

function calculatePercentage(curValue: number, totalValue: number) {
  if (totalValue === 0) {
    return 0;
  }
  return (curValue / totalValue) * 100;
}

export default function Statistics({
  timeFrame,
  timeWatched,
  totalTimeWatched,
  completedRewards,
  totalRewards,
  courseOverallProgress,
  handleStatTimeFrameChange,
}: Props) {
  const classes = useStatStyles();

  return (
    <Paper className={classes.paper}>
      <Box className={`${classes.flex} ${classes.headingContainer}`}>
        <Typography className={classes.heading}>Key Statistics</Typography>
        <SelectDropdown
          options={STAT_OPTIONS}
          currentValue={timeFrame}
          handleChange={handleStatTimeFrameChange}
        />
      </Box>
      <Box className={`${classes.flex} ${classes.contentContainer}`}>
        <CircularProgressWithLabel
          title={`${timeWatched} hours`}
          description={"time watched"}
          icon={TimeWatchedFlag}
          value={calculatePercentage(timeWatched, totalTimeWatched)}
          color="#FF0000"
        />
        <CircularProgressWithLabel
          title={`${courseOverallProgress}% completed`}
          description={"course overall progress"}
          icon={ProgressFlag}
          value={courseOverallProgress}
          color="#9F2F72"
        />
        <CircularProgressWithLabel
          title={`${completedRewards}/${totalRewards}`}
          description={"rewards"}
          icon={RewardFlag}
          value={calculatePercentage(completedRewards, totalRewards)}
          color="#007AF2"
        />
      </Box>
    </Paper>
  );
}

export const useStatStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: "20px",
      borderRadius: "20px",
    },
    headingContainer: {
      padding: "20px 15px",
      [theme.breakpoints.up("sm")]: {
        padding: "20px 30px",
      },
    },
    heading: {
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "26px",
      color: "#0A1629",
    },
    flex: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    skeletonContainer: {
      flexWrap: "wrap",
      paddingTop: "5px",
      margin: "0 10px",
      [theme.breakpoints.up("sm")]: {
        margin: "0 40px",
      },
    },
    contentContainer: {
      flexWrap: "wrap",
      paddingTop: "20px",
      margin: "0 10px",
      [theme.breakpoints.up("sm")]: {
        margin: "0 40px",
      },
    },
    coursesContainer: {
      justifyContent: "flex-end",
    },
    noCoursesContainer: {
      justifyContent: "center",
    },
    noCourseText: {
      textTransform: "capitalize",
    },
  })
);
